import React from "react";

export const Yclient = () => {
    return (
        <iframe
            height="745px"
            width="40%"
            // scrolling="no"
            frameBorder="0"
            // allowTransparency="true"
            id="ms_booking_iframe"
            src="https://n1267021.yclients.com/company/1128518/personal/menu?o="
            // style={{margin: 0}}
            style={{borderRadius: "15px"}}
        ></iframe>
    );
};
